import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { DashboardGuardService } from './core/guards/dashboard-guard.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then((mod) => mod.LoginModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./features/registration/registration.module').then((mod) => mod.RegistrationModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.module').then((mod) => mod.DashboardModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'productmanager',
    loadChildren: () => import('./features/product-manager/product-manager.module').then((mod) => mod.ProductManagerModule),
    canActivate: [DashboardGuardService]
  },
  {
    path: 'filemanager',
    loadChildren: () => import('./features/filemanager/filemanager.module').then((mod) => mod.FilemanagerModule),
    canActivate: [DashboardGuardService]
  },
  {
    path: 'user',
    loadChildren: () => import('./features/user/user.module').then((mod) => mod.UserModule),
    canActivate: [DashboardGuardService]
  },
  {
    path: 'product',
    loadChildren: () => import('./features/product/product.module').then((mod) => mod.ProductModule),
    canActivate: [AuthGuardService]
  },
  {
    path: '**',
    redirectTo: '/login'
  }
  //{ path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
